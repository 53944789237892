import { Box, Flex } from "@chakra-ui/react";
import { graphql, useStaticQuery } from "gatsby";

import BlockContent from "@sanity/block-content-to-react";
import React from "react";

const Impressum = () => {
  const data = useStaticQuery(graphql`
    {
      sanityImpressum {
        _rawImpressumHtml
      }
    }
  `);

  console.log(data);
  return (
    <Flex justify="center" align="center" py="32" borderBottom="1px solid black">
      <Box maxW="8xl" px="8">
        <BlockContent blocks={data.sanityImpressum._rawImpressumHtml} />
      </Box>
    </Flex>
  );
};

export default Impressum;
